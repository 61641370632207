import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const PostTemplate = ({ data }) => {
  const { title } = data.mdx.frontmatter
  const { body } = data.mdx

  return (
    <Layout>
      <section>
        <Link className="btn" to="/">
          返回全部作品集
        </Link>
        <div>
          <h1>{title}</h1>
        </div>
        <div>
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query getPost($title: String!) {
    mdx(frontmatter: { title: { eq: $title } }) {
      frontmatter {
        title
      }
      body
    }
  }
`

export default PostTemplate
